



















import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
      default: "",
    },
  },

  setup(_, { root, emit }) {
    const model = reactive({
      nfcTag: null,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
    });

    const closeDialog = () => {
      emit("close-dialog");
    };

    const print = () => {
      const node: any = document.querySelector(".mail-content");

      const mywindow: any = window.open("", "PRINT", "height=800,width=1000");

      mywindow.document.write(node.innerHTML);

      mywindow.document.close();
      mywindow.focus();

      setTimeout(mywindow.print(), 1000);
      mywindow.close();
    };

    const getFile = (id: string, name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`mail-attachment/${id}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };

    return {
      model,
      state,
      closeDialog,
      print,
      getFile,
    };
  },
});
